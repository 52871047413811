<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <Loading v-if="pgLoading"></Loading>

        <div class="tab-danger col-12" v-if="!pgLoading">
          <ul
            class="
              nav nav-tabs nav-line-tabs nav-stretch
              fs-6
              border-0
              fw-bolder
            "
            role="tablist"
          >
            <li class="nav-item">
              <a
                href="#basic_info"
                class="nav-link active text-active-primary"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="true"
                >{{ $t("app.basic_info") }}</a
              >
            </li>
          </ul>

          <div class="tab-content col-12 p-0" v-if="!pgLoading">
            <div
              role="tabpanel"
              class="tab-pane fade active show"
              id="basic_info"
            >
              <div class="p-0 row">
                <div v-if="!pgLoading" class="col-md-8 col-xs-12">
                  <CardUser
                    :client="row.user"
                    v-on:clientChange="handleClientChange"
                    :hasSendInvoice="true"
                    :sendInvoice="row.payment_date_info.send_invoice"
                    @sendInvoiceChange="handleSendInvoiceChange"
                  >
                  </CardUser>

                  <FormSingleLang
                    :label="$t('view.invoice_info')"
                    :labelTitle="$t('view.subject')"
                    :labelBody="$t('view.details')"
                    :hasTitle="true"
                    :title="row.subject"
                    :hasBody="true"
                    :bus="bus"
                    :body="row.details"
                    v-on:formChange="handleFormChange"
                  >
                  </FormSingleLang>
                </div>

                <div v-if="!pgLoading" class="col-md-4 col-xs-12">
                  <CardCompany
                    v-if="auth.role == 'root'"
                    :company_id="row.company_id"
                    @companyChange="handleCompanyChange"
                  />
                  <CardAmount
                    :amount="row.amount"
                    :bus="bus"
                    @inputChange="handleAmountChange"
                  />

                  <CardDate
                    :date="row.invoice_date"
                    minDate="2"
                    :labelDate="$t('app.invoice_date')"
                    @dateChange="handleInvoiceDateChange"
                  />
                  <CardPayAfter
                    :pay_after="row.pay_after"
                    @expireDateChange="handleExpireDateValueChange"
                  />
                  <CardDate
                    :date="row.pay_after"
                    v-if="
                      row.pay_after &&
                      (row.pay_after == 'CUSTOM' || row.pay_after.includes('-'))
                    "
                    @dateChange="handleExpireDateChange"
                  />

                  <CardInputWithGroupText
                    v-if="row.pay_after != 'NOT_EXPIRE'"
                    :input="row.payment_date_info.tolerance_for_delay"
                    :groupText="$t('app.day')"
                    :label="$t('app.tolerance_for_delay')"
                    @inputChange="handleToleranceForDelayChange"
                  />

                  <CardScheduleType
                    :scheduling_type="row.payment_date_info.scheduling_type"
                    @schedulingTypeChange="handleSchedulingTypeChange"
                  />

                  <CardWeek
                    v-if="row.payment_date_info.scheduling_type == 'WEEKLY'"
                    :scheduling_day="row.payment_date_info.scheduling_day"
                    @schedulingDayChange="handleSchedulingDayChange"
                  />

                  <CardMonth
                    v-if="
                      ['MONTHLY', 'YEARLY'].includes(
                        row.payment_date_info.scheduling_type
                      )
                    "
                    :scheduling_day="row.payment_date_info.scheduling_day"
                    @schedulingDayChange="handleSchedulingDayChange"
                  />

                  <CardMonths
                    v-if="row.payment_date_info.scheduling_type == 'YEARLY'"
                    :scheduling_month="row.payment_date_info.scheduling_month"
                    @schedulingMonthChange="handleSchedulingMonthChange"
                  />
                  <CardScheduleFinishType
                    v-if="
                      row.payment_date_info.scheduling_type !=
                      'ONE_TIME_INVOICE'
                    "
                    :scheduling_finish_type="
                      row.payment_date_info.scheduling_finish_type
                    "
                    @schedulingFinishTypeChange="
                      handleSchedulingFinishTypeChange
                    "
                  />
                  <CardDate
                    v-if="
                      row.payment_date_info.scheduling_type !=
                        'ONE_TIME_INVOICE' &&
                      row.payment_date_info.scheduling_finish_type == 'ON'
                    "
                    :date="row.payment_date_info.scheduling_finish_on_date"
                    :labelDate="$t('app.finish_date')"
                    @dateChange="handleSchedulingFinishOnDateChange"
                  />
                  <CardInput
                    v-if="
                      row.payment_date_info.scheduling_type !=
                        'ONE_TIME_INVOICE' &&
                      row.payment_date_info.scheduling_finish_type == 'AFTER'
                    "
                    :label="$t('app.payment_times')"
                    :input="row.payment_date_info.scheduling_finish_after_times"
                    @inputChange="handleSchedulingFinishAfterTimesChange"
                  />

                  <CardReturnedVoucher
                    v-if="row.payment_status != 'pending'"
                    :payment_status="row.payment_status"
                    :returned_voucher_base64="row.returned_voucher_base64"
                    :returned_voucher_preview="row.returned_voucher_preview"
                    v-on:returnedVoucherChange="handleReturnedVoucherChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { defineComponent } from "vue";
import CardUser from "@/components/CardUser";
import CardPayAfter from "@/components/CardPayAfter";
import CardAmount from "@/components/CardAmount";
import CardCompany from "@/components/CardCompany";
import Loading from "@/components/Loading";
import Buttons from "@/components/Buttons";
import mitt from "mitt";
import CardDate from "@/components/CardDate";
import CardScheduleType from "@/components/CardScheduleType";
import CardInput from "@/components/CardInput";
import CardScheduleFinishType from "@/components/CardScheduleFinishType";
import CardWeek from "@/components/CardWeek";
import CardMonth from "@/components/CardMonth";
import CardMonths from "@/components/CardMonths";
import FormSingleLang from "@/components/FormSingleLang";
import CardReturnedVoucher from "@/components/CardReturnedVoucher";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CardInputWithGroupText from "@/components/CardInputWithGroupText.vue";

const bus = mitt();

export default defineComponent({
  name: "EditOrNew",
  components: {
    CardInputWithGroupText,
    CardReturnedVoucher,
    FormSingleLang,
    CardMonths,
    CardMonth,
    CardWeek,
    CardInput,
    CardScheduleType,
    CardDate,
    CardPayAfter,
    CardAmount,
    CardCompany,
    CardUser,
    Loading,
    Buttons,
    CardScheduleFinishType,
  },
  data() {
    return {
      // auth
      bus,
      auth: {
        role: "",
        id_token: "",
      },

      // row
      row: {
        subject: "",
        payment_status: "pending",
        returned_voucher_base64: "",
        details: "",
        amount: "",
        company_id: null,
        pay_after: "NOT_EXPIRE",
        invoice_date: "",
        user: {},
        payment_date_info: {
          scheduling_type: "ONE_TIME_INVOICE",
          scheduling_day: "",
          scheduling_month: "",
          scheduling_finish_type: "ON",
          scheduling_finish_on_date: "",
          scheduling_finish_after_times: "",
          send_invoice: 4,
          tolerance_for_delay: 0,
        },
      },

      msgCurrent: this.$route.params.id ? "Updated" : "Created",
      btnCurrent: "Send",
      methodType: this.$route.params.id ? "PUT" : "POST",
      pgLoading: !!this.$route.params.id,
      pgCurrent: this.$route.params.id ? "Edit" : "Add New",
      btnLoading: false,
      refs: "invoices",
    };
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }
    this.initialDate();
    setCurrentPageTitle(this.$t("nav.invoices"), "bx bx-money");
    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {
    initialDate() {
      const dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      this.row.invoice_date = year + "-" + month + "-" + day;
    },
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.amount = res.data.row.amount;
          this.row.details = res.data.row.details;
          this.row.subject = res.data.row.subject;
          this.row.payment_status = res.data.row.payment_status;
          this.row.pay_after = res.data.row.pay_after;
          this.row.invoice_date = res.data.row.invoice_date;
          this.row.payment_date_info = res.data.row.payment_date_info;
          this.row.user = res.data.row.user;
          this.row.company_id = res.data.row.company_id;
          this.row.returned_voucher_base64 = res.data.row.returned_voucher.url;
          this.row.returned_voucher_preview = res.data.row.returned_voucher.url;
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const options = {
        url: this.$route.params.id
          ? this.refs + "/" + this.$route.params.id
          : this.refs,
        method: this.methodType,
        data: {
          amount: this.row.amount.replaceAll(",", ""),
          details: this.row.details,
          subject: this.row.subject,
          payment_date_info: this.row.payment_date_info,
          pay_after: this.row.pay_after,
          invoice_date: this.row.invoice_date,
          user: this.row.user,
          company_id: this.row.company_id,
          returned_voucher_base64: this.row.returned_voucher_base64,
        },
      };
      this.axios(options, config)
        .then(() => {
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم الارسال بنجاح",
          });

          this.$router.push({ name: this.refs });
        })
        .catch((err) => {
          this.btnLoading = false;
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: this.refs });
      }
    },
    handleSortChange(event) {
      this.row.sort = event.sort;
    },
    handleClientChange(event) {
      this.row.user = event;
      this.row.subject = event.last_invoice_title;
      this.bus.emit("last_invoice_title", event.last_invoice_title);
    },
    handleExpireDateValueChange(event) {
      this.row.pay_after = event.pay_after.toString();
    },
    handleFormChange(event) {
      this.row.subject = event.title;
      this.row.details = event.body;
    },
    handleAmountChange(event) {
      this.row.amount = event.amount;
    },
    handleCompanyChange(event) {
      this.row.company_id = event.id;
      this.bus.emit("currency_changed", event.payment.currency);
    },
    handleInvoiceDateChange(event) {
      this.row.invoice_date = event.date;
    },
    handleExpireDateChange(event) {
      this.row.pay_after = event.date;
    },
    handleSendInvoiceChange(event) {
      this.row.payment_date_info.send_invoice = event.sendInvoice;
    },
    handleToleranceForDelayChange(event) {
      this.row.payment_date_info.tolerance_for_delay = event.input;
    },
    handleSchedulingTypeChange(event) {
      this.row.payment_date_info.scheduling_type = event.scheduling_type;
      this.row.payment_date_info.scheduling_day = "";
      this.row.payment_date_info.scheduling_month = "";
    },
    handleSchedulingFinishTypeChange(event) {
      this.row.payment_date_info.scheduling_finish_type =
        event.scheduling_finish_type;
    },
    handleSchedulingFinishOnDateChange(event) {
      this.row.payment_date_info.scheduling_finish_on_date = event.date;
    },
    handleSchedulingFinishAfterTimesChange(event) {
      this.row.payment_date_info.scheduling_finish_after_times = event.input;
    },
    handleSchedulingDayChange(event) {
      this.row.payment_date_info.scheduling_day = event.scheduling_day;
    },
    handleSchedulingMonthChange(event) {
      this.row.payment_date_info.scheduling_month = event.scheduling_month;
    },
    handleReturnedVoucherChange(event) {
      this.row.returned_voucher_base64 = event.returned_voucher_base64;
    },
  },
});
</script>

<style scoped>
.nav .nav-item {
  font-size: 15px !important;
}
</style>
