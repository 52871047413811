<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ $t("app.on") }}
      </h4>
      <div class="form-group">
        <el-select
          v-model="scheduling_day_label"
          class="form-select-solid col-12"
          :placeholder="row.scheduling_day_label"
        >
          <el-option :label="$t('weekDays.Sunday')" value="sunday">
            <a class="dropdown-item" @click="onChange('sunday')">
              {{ $t("weekDays.Sunday") }}
            </a>
          </el-option>
          <el-option :label="$t('weekDays.Monday')" value="monday">
            <a class="dropdown-item" @click="onChange('monday')">
              {{ $t("weekDays.Monday") }}
            </a>
          </el-option>
          <el-option :label="$t('weekDays.Tuesday')" value="tuesday">
            <a class="dropdown-item" @click="onChange('tuesday')">
              {{ $t("weekDays.Tuesday") }}
            </a>
          </el-option>
          <el-option :label="$t('weekDays.Wednesday')" value="wednesday">
            <a class="dropdown-item" @click="onChange('wednesday')">
              {{ $t("weekDays.Wednesday") }}
            </a>
          </el-option>
          <el-option :label="$t('weekDays.Thursday')" value="thursday">
            <a class="dropdown-item" @click="onChange('thursday')">
              {{ $t("weekDays.Thursday") }}
            </a>
          </el-option>
          <el-option :label="$t('weekDays.Friday')" value="friday">
            <a class="dropdown-item" @click="onChange('friday')">
              {{ $t("weekDays.Friday") }}
            </a>
          </el-option>
          <el-option :label="$t('weekDays.Saturday')" value="saturday">
            <a class="dropdown-item" @click="onChange('saturday')">
              {{ $t("weekDays.Saturday") }}
            </a>
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWeek",
  props: ["scheduling_day"],
  data() {
    return {
      dataLoading: false,
      auth: {
        id_token: "",
      },
      scheduling_day_label: "",
      row: {
        scheduling_day: this.$props.scheduling_day,
      },
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    this.checkLabel();
  },
  methods: {
    checkLabel() {
      if (this.$props.scheduling_day == "sunday")
        this.scheduling_day_label = this.$t("weekDays.Sunday");
      else if (this.$props.scheduling_day == "monday")
        this.scheduling_day_label = this.$t("weekDays.Monday");
      else if (this.$props.scheduling_day == "tuesday")
        this.scheduling_day_label = this.$t("weekDays.Tuesday");
      else if (this.$props.scheduling_day == "wednesday")
        this.scheduling_day_label = this.$t("weekDays.Wednesday");
      else if (this.$props.scheduling_day == "thursday")
        this.scheduling_day_label = this.$t("weekDays.Thursday");
      else if (this.$props.scheduling_day == "friday")
        this.scheduling_day_label = this.$t("weekDays.Friday");
      else if (this.$props.scheduling_day == "saturday")
        this.scheduling_day_label = this.$t("weekDays.Saturday");
    },
    onChange(value) {
      this.row.scheduling_day = value;
      this.checkLabel();
      const data = {
        scheduling_day: this.row.scheduling_day,
      };
      this.$emit("schedulingDayChange", data);
    },
  },
};
</script>

<style scoped>
.scrollable-menu {
  height: auto;
  max-height: 18rem;
  overflow-x: hidden;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c;
  background-color: #eceeef;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #464a4c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c !important;
  background-color: #eceeef;
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #ced4da;
  border-left: none;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}
</style>
