<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ labelDate }}
      </h4>
      <div class="col-md-12" style="padding: 0">
        <input
          id="date"
          class="form-control"
          type="date"
          v-model="row.date"
          v-on:change="onInputChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDate",
  props: ["labelDate", "date", "minDate"],
  data() {
    return {
      row: {
        date: this.$props.date,
      },
    };
  },
  mounted() {
    this.checkMinDate();
  },
  methods: {
    checkMinDate() {
      if (!this.minDate) {
        return;
      }

      var dtToday = new Date();

      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate() - this.minDate;
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var minDate = year + "-" + month + "-" + day;
      document.getElementById("date").min = minDate;
    },
    onInputChange() {
      const data = {
        date: this.row.date,
      };
      this.$emit("dateChange", data);
    },
  },
};
</script>
