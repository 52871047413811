<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : 'txt-left'"
      >
        {{ label }}
      </h4>
      <div class="col-12 input-group pd-0" style="padding: 0">
        <input
          id="input"
          class="form-control text-lowercase"
          :type="type"
          :placeholder="label"
          v-model="row.input"
          @paste="onPaste"
          v-on:input="onChange"
        />
        <div class="input-group-append">
          <span class="input-group-text">{{ row.groupText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardInputWithGroupText",
  props: ["label", "type", "input", "groupText"],
  data() {
    return {
      row: {
        input: this.$props.input,
        groupText: this.$props.groupText,
      },
    };
  },
  methods: {
    onChange() {
      const data = {
        input: this.row.input,
      };
      this.$emit("inputChange", data);
    },
  },
};
</script>
