<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ $t("app.month") }}
      </h4>
      <div class="form-group">
        <el-select
          v-model="scheduling_month_label"
          class="form-select-solid col-12"
          :placeholder="row.scheduling_month_label"
        >
          <el-option :label="$t('months.January')" value="january">
            <a class="dropdown-item" @click="onChange('january')">
              {{ $t("months.January") }}
            </a>
          </el-option>
          <el-option :label="$t('months.February')" value="february">
            <a class="dropdown-item" @click="onChange('february')">
              {{ $t("months.February") }}
            </a>
          </el-option>
          <el-option :label="$t('months.March')" value="march">
            <a class="dropdown-item" @click="onChange('march')">
              {{ $t("months.March") }}
            </a>
          </el-option>
          <el-option :label="$t('months.April')" value="april">
            <a class="dropdown-item" @click="onChange('april')">
              {{ $t("months.April") }}
            </a>
          </el-option>
          <el-option :label="$t('months.May')" value="may">
            <a class="dropdown-item" @click="onChange('may')">
              {{ $t("months.May") }}
            </a>
          </el-option>
          <el-option :label="$t('months.June')" value="june">
            <a class="dropdown-item" @click="onChange('june')">
              {{ $t("months.June") }}
            </a>
          </el-option>
          <el-option :label="$t('months.July')" value="july">
            <a class="dropdown-item" @click="onChange('july')">
              {{ $t("months.July") }}
            </a>
          </el-option>
          <el-option :label="$t('months.August')" value="august">
            <a class="dropdown-item" @click="onChange('august')">
              {{ $t("months.August") }}
            </a>
          </el-option>
          <el-option :label="$t('months.September')" value="september">
            <a class="dropdown-item" @click="onChange('september')">
              {{ $t("months.September") }}
            </a>
          </el-option>
          <el-option :label="$t('months.October')" value="october">
            <a class="dropdown-item" @click="onChange('october')">
              {{ $t("months.October") }}
            </a>
          </el-option>
          <el-option :label="$t('months.November')" value="november">
            <a class="dropdown-item" @click="onChange('november')">
              {{ $t("months.November") }}
            </a>
          </el-option>
          <el-option :label="$t('months.December')" value="december">
            <a class="dropdown-item" @click="onChange('december')">
              {{ $t("months.December") }}
            </a>
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardMonths",
  props: ["scheduling_month"],
  data() {
    return {
      dataLoading: false,
      auth: {
        id_token: "",
      },
      scheduling_month_label: "",
      row: {
        scheduling_month: this.$props.scheduling_month,
      },
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    this.checkLabel();
  },
  methods: {
    checkLabel() {
      if (this.$props.scheduling_month == "january")
        this.scheduling_month_label = this.$t("months.January");
      else if (this.$props.scheduling_month == "february")
        this.scheduling_month_label = this.$t("months.February");
      else if (this.$props.scheduling_month == "march")
        this.scheduling_month_label = this.$t("months.March");
      else if (this.$props.scheduling_month == "april")
        this.scheduling_month_label = this.$t("months.April");
      else if (this.$props.scheduling_month == "may")
        this.scheduling_month_label = this.$t("months.May");
      else if (this.$props.scheduling_month == "june")
        this.scheduling_month_label = this.$t("months.June");
      else if (this.$props.scheduling_month == "july")
        this.scheduling_month_label = this.$t("months.July");
      else if (this.$props.scheduling_month == "august")
        this.scheduling_month_label = this.$t("months.August");
      else if (this.$props.scheduling_month == "september")
        this.scheduling_month_label = this.$t("months.September");
      else if (this.$props.scheduling_month == "october")
        this.scheduling_month_label = this.$t("months.October");
      else if (this.$props.scheduling_month == "november")
        this.scheduling_month_label = this.$t("months.November");
      else if (this.$props.scheduling_month == "december")
        this.scheduling_month_label = this.$t("months.December");
    },
    onChange(value) {
      this.row.scheduling_month = value;
      this.checkLabel();
      const data = {
        scheduling_month: this.row.scheduling_month,
      };
      this.$emit("schedulingMonthChange", data);
    },
  },
};
</script>

<style scoped>
.scrollable-menu {
  height: auto;
  max-height: 18rem;
  overflow-x: hidden;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c;
  background-color: #eceeef;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #464a4c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c !important;
  background-color: #eceeef;
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #ced4da;
  border-left: none;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}
</style>
