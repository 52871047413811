<template>
  <div class="card">
    <div class="card-body">
      <input
        class="form-check-input"
        type="checkbox"
        name="formRadios"
        id="formRadios1"
        style="margin-right: 0px"
        :checked="row.payment_status == 'returned' ? true : false"
        @input="checkboxChanged"
      />
      <label
        style="margin-right: 25px"
        class="form-check-label"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
        for="formRadios1"
      >
        {{ $t("view.returned") }}
      </label>

      <div class="mt-3" v-if="row.returned">
        <p>
          <inline-svg
            v-if="
              row.returned_voucher_preview &&
              row.returned_voucher_preview != 'remove'
            "
            src="media/icons/duotune/files/fil003.svg"
            style="width: 150px; height: 200px"
          />
        </p>
        <button
          type="button"
          class="btn btn-primary waves-effect waves-light w-sm"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
          @click="openReturnedVoucher"
        >
          <i class="mdi mdi-upload font-size-16"></i>
          {{ row.upload_btn }}
        </button>

        <input
          type="file"
          class="form-control hidden"
          ref="myDropify"
          v-on:change="onReturnedVoucherChange"
          accept="file/*"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardReturnedVoucher",
  props: [
    "returned_voucher_base64",
    "returned_voucher_preview",
    "upload_btn",
    "payment_status",
  ],
  data() {
    return {
      row: {
        returned_voucher_base64: this.$props.returned_voucher_base64,
        returned_voucher_preview: this.$props.returned_voucher_preview,
        upload_btn: "",
        payment_status: this.$props.payment_status,
        returned: this.$props.payment_status == "returned" ? 1 : 0,
      },
    };
  },
  mounted() {
    if (this.$props.returned_voucher_preview) {
      this.row.upload_btn = this.$t("app.change_upload_voucher");
    } else {
      this.row.upload_btn = this.$t("app.upload_voucher");
    }
  },
  methods: {
    checkboxChanged() {
      this.row.returned = this.row.returned == 1 ? 0 : 1;
      if (this.row.returned == 0) {
        this.row.returned_voucher_preview = "remove";
        this.row.returned_voucher_base64 = "remove";
        this.afterReturnedVoucherUpload();
      }
    },
    afterReturnedVoucherUpload() {
      const data = {
        returned_voucher_preview: this.row.returned_voucher_preview,
        returned_voucher_base64: this.row.returned_voucher_base64,
      };
      this.$emit("returnedVoucherChange", data);
    },

    openReturnedVoucher() {
      this.$refs.myDropify.click();
    },

    onReturnedVoucherChange(e) {
      const returned_voucher = e.target.files[0];
      this.row.returned_voucher_preview = URL.createObjectURL(returned_voucher);
      this.createBase64ReturnedVoucher(returned_voucher);
    },

    createBase64ReturnedVoucher(returned_voucherObject) {
      const reader = new FileReader();
      reader.readAsDataURL(returned_voucherObject);
      reader.onload = (e) => {
        this.row.returned_voucher_base64 = e.target.result;
        this.afterReturnedVoucherUpload();
      };
    },
  },
});
</script>
