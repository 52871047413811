<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ $t("app.payment_link_validity") }}
      </h4>
      <div class="form-group">
        <el-select
          v-model="expire_date_label"
          class="form-select-solid col-12"
          :placeholder="row.expire_date_label"
        >
          <el-option :label="$t('view.after_one_day')" value="1">
            <a class="dropdown-item" @click="onChange(1)">
              {{ $t("view.after_one_day") }}
            </a>
          </el-option>
          <el-option :label="$t('view.after_ten_day')" value="10">
            <a class="dropdown-item" @click="onChange(10)">
              {{ $t("view.after_ten_day") }}
            </a>
          </el-option>
          <el-option :label="$t('view.after_fifteen_day')" value="15">
            <a class="dropdown-item" @click="onChange(15)">
              {{ $t("view.after_fifteen_day") }}
            </a>
          </el-option>
          <el-option :label="$t('view.after_thirty_day')" value="30">
            <a class="dropdown-item" @click="onChange(30)">
              {{ $t("view.after_thirty_day") }}
            </a>
          </el-option>
          <el-option :label="$t('view.after_forty_five_day')" value="45">
            <a class="dropdown-item" @click="onChange(45)">
              {{ $t("view.after_forty_five_day") }}
            </a>
          </el-option>
          <el-option :label="$t('view.not_expire')" value="NOT_EXPIRE">
            <a class="dropdown-item" @click="onChange('NOT_EXPIRE')">
              {{ $t("view.not_expire") }}
            </a>
          </el-option>
          <el-option :label="$t('view.custom')" value="CUSTOM">
            <a class="dropdown-item" @click="onChange('CUSTOM')">
              {{ $t("view.custom") }}
            </a>
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardPayAfter",
  props: ["pay_after"],
  data() {
    return {
      dataLoading: false,
      auth: {
        id_token: "",
      },
      expire_date_label: "",
      row: {
        pay_after: this.$props.pay_after,
      },
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    this.checkLabel();
  },
  methods: {
    checkLabel() {
      if (this.$props.pay_after == 1 || this.$props.pay_after == undefined)
        this.expire_date_label = this.$t("view.after_one_day");
      else if (this.$props.pay_after == 10)
        this.expire_date_label = this.$t("view.after_ten_day");
      else if (this.$props.pay_after == 15)
        this.expire_date_label = this.$t("view.after_fifteen_day");
      else if (this.$props.pay_after == 30)
        this.expire_date_label = this.$t("view.after_thirty_day");
      else if (this.$props.pay_after == 45)
        this.expire_date_label = this.$t("view.after_forty_five_day");
      else if (this.$props.pay_after == "NOT_EXPIRE")
        this.expire_date_label = this.$t("view.not_expire");
      else if (
        this.$props.pay_after == "CUSTOM" ||
        this.$props.pay_after.includes("-")
      )
        this.expire_date_label = this.$t("view.custom");
    },
    onChange(value) {
      this.row.pay_after = value;
      this.checkLabel();
      const data = {
        pay_after: this.row.pay_after,
      };
      this.$emit("expireDateChange", data);
    },
  },
};
</script>

<style scoped>
.scrollable-menu {
  height: auto;
  max-height: 18rem;
  overflow-x: hidden;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c;
  background-color: #eceeef;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #464a4c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c !important;
  background-color: #eceeef;
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #ced4da;
  border-left: none;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}
</style>
